import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModCovidHighlight from "../../../components/Chronology/Modules/ModCovidHighlight";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";

export const frontmatter = {
  title: "Día -11",
  week: "Semana -1",
  day: "02",
  month: "Mar",
  monthNumber: "03",
  date: "2020-03-02",
  path: "/cronologia/semana-previa-1#dia-02-mar",
};
const Day09M = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModDataEu fecha={frontmatter.date} />
        <ModCovidHighlight>
          República Checa declara su primer caso confirmado
        </ModCovidHighlight>
      </ContentLeft>

      <ContentRight>
        <ModText>
          <strong>En España</strong>, se han registrado 41 más que el día
          anterior.
        </ModText>
        <ModText>
          El <strong>Ministerio de Sanidad</strong> mantiene el escenario de
          contención (nivel 1) ya que, por el momento, nada indica que haya una
          transmisión comunitaria descontrolada y una entrada masiva de casos
          importados.
        </ModText>
        <ModImage
          src="/images/svg/02-03-spain.svg"
          alt="Casos confirmados en España"
        />

        <ModText>
          Por comunidades autónomas Madrid se destatca como comunidad más
          afectada con 29 casos. El desglose es: 15 en la Comunidad Valenciana,
          15 en Cataluña, 12 en Andalucía, 10 en Cantabria, 9 en el País Vasco,
          7 en Canarias (uno ya dado de alta), 6 en Extremadura, 3 en Castilla y
          León, 3 en Castilla La Mancha, 2 en Baleares, 1 en Navarra, 1 en
          Asturias y 1 en La Rioja.
        </ModText>
        <ModCovidHighlight>
          Castilla la Mancha declara sus primeros casos
        </ModCovidHighlight>
        <ModCovidHighlight>Cantabria declara sus primer caso</ModCovidHighlight>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day09M;
