import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModCovidHighlight from "../../../components/Chronology/Modules/ModCovidHighlight";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";

export const frontmatter = {
  title: "Día -09",
  week: "Semana -1",
  day: "04",
  month: "Mar",
  monthNumber: "03",
  date: "2020-03-04",
  path: "/cronologia/semana-previa-1#dia-04-mar",
};
const Day09M = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModDataEu fecha={frontmatter.date} />
        <ModCovidHighlight>
          Polonia registran sus primeros casos positivos.
        </ModCovidHighlight>
        <ModCovidHighlight>
          Ucrania registran sus primeros casos positivos.
        </ModCovidHighlight>
      </ContentLeft>

      <ContentRight>
        <ModText>
          <strong>En España</strong>, se han registrado 48 casos más que el día
          anterior, y la velocidad de duplicación se establece en dos días. Con
          un fallecido, el ratio de defunciones frente a los casos positivos es
          de 0,5%. El total de personas en UCI asciende a 7.
        </ModText>
        <ModImage
          src="/images/svg/04-03-spain.svg"
          alt="Casos confirmados en España"
        />

        <ModText>
          De los casos confirmados, 70 corresponden a Madrid, 19 a la Comunidad
          Valenciana, 17 al País Vasco, 15 a Cataluña, 13 a Andalucía, 12 a
          Castilla-La Mancha, 11 a Castilla y León, 10 a Cantabria, 7 a Canarias
          y La Rioja, 6 a Extremadura, 5 a Baleares, 3 a Navarra, 2 a Asturias y
          1 a Galicia.
        </ModText>
        <ModText>
          Se ha identificado a la primera víctima mortal por coronavirus. Se
          trata de un paciente que falleció el pasado 13 de febrero en el
          Hospital Arnau de Vilanova (Valencia), que en el momento de su
          fallecimiento no cumplía con la definición de caso. Al ampliarse,
          posteriormente, dicha definición ha sido considerado un caso positivo.
        </ModText>

        <ModCovidHighlight>
          Galicia declara su primer caso positivo
        </ModCovidHighlight>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day09M;
