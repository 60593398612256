import React from "react";
import { graphql } from "gatsby";
import LayoutWeek from "../../../components/Chronology/LayoutWeek";
import Day29Feb from "./dia-29-feb";
import Day01Mar from "./dia-01-mar";
import Day02Mar from "./dia-02-mar";
import Day03Mar from "./dia-03-mar";
import Day04Mar from "./dia-04-mar";
import Day05Mar from "./dia-05-mar";
import Day06Mar from "./dia-06-mar";

import SecWeekly from "../../../components/Chronology/Modules/SecWeekly";

const Week = (props) => {
  return (
    <LayoutWeek {...props}>
      <SecWeekly
        period="29 Febrero - 06 Marzo"
        week="Semana -1 de confinamiento"
      ></SecWeekly>
      <Day06Mar {...props} />
      <Day05Mar {...props} />
      <Day04Mar {...props} />
      <Day03Mar {...props} />
      <Day02Mar {...props} />
      <Day01Mar {...props} />
      <Day29Feb {...props} />
    </LayoutWeek>
  );
};

export const pageQuery = graphql`
  query {
    allSitePage(
      filter: { id: { regex: "/semana-[0-9]+/$/" } }
      sort: { fields: path, order: DESC }
    ) {
      edges {
        node {
          path
        }
      }
    }
  }
`;
export default Week;
