import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModCovidHighlight from "../../../components/Chronology/Modules/ModCovidHighlight";
import ModDashedLine from "../../../components/Chronology/Modules/ModDashedLine";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";

export const frontmatter = {
  title: "Día -13",
  week: "Semana -1",
  day: "29",
  month: "feb",
  monthNumber: "02",
  date: "2020-02-29",
  path: "/cronologia/semana-previa-1#dia-29-feb",
};
const Day09M = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModText>
          <strong>Italia</strong> ha notificado un total de 888 casos, de los
          cuales, 827 pertenecen a las regiones de Lombardía (531), Véneto (151)
          y Emilia Romagna (145), ubicadas en el norte del país. El número de
          fallecidos asciende a 21 personas.
        </ModText>
        <ModDashedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModCovidHighlight>
          Mónaco confirma su primer caso positivo
        </ModCovidHighlight>
        <ModCovidHighlight>
          Islandia confirma sus primero caso positivo.
        </ModCovidHighlight>
        <ModCovidHighlight>
          Azerbaiyán confirman sus primer caso positivo.
        </ModCovidHighlight>
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, hasta el momento se han registrado 50
          casos (18 más que el día anterior)
        </ModText>
        <ModImage
          src="/images/svg/29-02-spain.svg"
          alt="Casos confirmados en España"
        />
        <ModText>
          La distribución territoral por comunidades es de: 13 Comunidad
          Valenciana, 10 en Madrid, 6 Cataluña, 6 en Canarias, 8 Andalucía, 2
          Castilla y León, 2 en Baleares y 3 en el País Vasco.
        </ModText>
        <ModText>
          El caso notificado en Aragón ha dado negativo en los segundos
          análisis, por lo que ha quedado descartado.
        </ModText>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day09M;
