import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModReferenceList from "../../../components/Chronology/Modules/ModReferenceList";
import ReferenceRow from "../../../components/Chronology/Modules/ReferenceRow";
import ModCovidHighlight from "../../../components/Chronology/Modules/ModCovidHighlight";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import InlineLink from "../../../components/Chronology/Modules/InlineLink";

export const frontmatter = {
  title: "Día -08",
  week: "Semana -1",
  day: "05",
  month: "Mar",
  monthNumber: "03",
  date: "2020-03-05",
  path: "/cronologia/semana-previa-1#dia-05-mar",
};
const Day09M = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModDataEu fecha={frontmatter.date} />
        <ModCovidHighlight>
          Eslovenia registra sus primeros casos positivos.
        </ModCovidHighlight>
        <ModCovidHighlight>
          Hungría declara su primer caso confirmado.
        </ModCovidHighlight>
      </ContentLeft>

      <ContentRight>
        <ModText>
          <strong>En España</strong>, hasta el momento se han registrado 237
          casos (39 más que el día anterior) y 3 fallecidos (1,3% de los casos
          positivos). El total de personas en UCI asciende a 9.
        </ModText>
        <ModImage
          src="/images/svg/05-03-spain.svg"
          alt="Casos confirmados en España"
        />
        <ModText>
          De los casos confirmados, 90 corresponden a Madrid (1 fallecido), 24 a
          Cataluña, 19 a la Comunidad Valenciana (1 fallecido), 17 al País Vasco
          (1 fallecido), 13 a Castilla-La Mancha, 12 a Andalucía, 11 a Castilla
          y León y La Rioja, 10 a Cantabria, 8 a Canarias, 6 a Baleares y
          Extremadura, 5 a Asturias, 3 a Navarra, y 1 a Aragón y Galicia.
        </ModText>
        <ModText>
          El <strong>Ministerio de Sanidad</strong> elabora un{" "}
          <InlineLink link="https://www.mscbs.gob.es/profesionales/saludPublica/ccayes/alertasActual/nCov-China/documentos/Centros_sociosanitarios.pdf">
            documento técnico
          </InlineLink>{" "}
          de recomendaciones a residencias de mayores y centros sociosanitarios
          para el COVID-19.
        </ModText>
        <ModTwoCols
          src="/images/svg/05_mar_Pabellon.svg"
          alt="Valencia Basket y Milán Euroliga"
          small={true}
          inverted={false}
        >
          Este jueves 5 de marzo se hacen efectivas las primeras medidas para
          frenar la propagación del coronavirus. El Valencia Basket y Milán de{" "}
          <strong>Euroliga</strong> disputan el primer partido a puerta cerrada
          en España.
        </ModTwoCols>
        <ModReferenceList title="Guías y documentos publicados">
          <ReferenceRow
            link="https://www.mscbs.gob.es/profesionales/saludPublica/ccayes/alertasActual/nCov-China/documentos/Centros_sociosanitarios.pdf"
            name="Recomendaciones a residencias de mayores y centros
            sociosanitarios"
          />
        </ModReferenceList>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day09M;
