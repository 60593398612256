import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModCovidHighlight from "../../../components/Chronology/Modules/ModCovidHighlight";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";

export const frontmatter = {
  title: "Día -07",
  week: "Semana -1",
  day: "06",
  month: "Mar",
  monthNumber: "03",
  date: "2020-03-06",
  path: "/cronologia/semana-previa-1#dia-06-mar",
};
const Day09M = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModDataEu fecha={frontmatter.date} />
        <ModCovidHighlight>
          Bosnia Herzegovina notifica su primer caso de COVID-19.
        </ModCovidHighlight>
      </ContentLeft>

      <ContentRight>
        <ModText>
          <strong>En España</strong>, hasta el momento se han registrado 365
          casos (128 más que el día anterior) y 5 fallecidos (1,4% de los casos
          positivos). El total de personas en UCI asciende a 11.
        </ModText>
        <ModImage
          src="/images/svg/06-03-spain.svg"
          alt="Casos confirmados en España"
        />
        <ModText>
          Las comunidades autónomas con una mayor incidencia acumulada en los
          últimos días son Madrid (137 casos y 2 fallecidos), País Vasco (45
          casos y 1 fallecido), Comunidad Valenciana (30 casos y 1 fallecido),
          La Rioja (29 casos), y Cataluña (24 casos).
        </ModText>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day09M;
