import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModCovidHighlight from "../../../components/Chronology/Modules/ModCovidHighlight";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";

export const frontmatter = {
  title: "Día -12",
  week: "Semana -1",
  day: "01",
  month: "Mar",
  monthNumber: "03",
  date: "2020-03-01",
  path: "/cronologia/semana-previa-1#dia-01-mar",
};
const Day09M = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModDataEu fecha={frontmatter.date} />
        <ModText>
          La lista la completan Dinamarca (3), Georgia (3), Azerbaiyán, Bélgica,
          Estonia, Irlanda, Islandia, Lituania, Países Bajos, Macedonia, Rumanía
          y San Marino.
        </ModText>

        <ModCovidHighlight>
          Luxemburgo declara su primer caso confirmado
        </ModCovidHighlight>
        <ModCovidHighlight>
          Irlanda declara su primer caso confirmado
        </ModCovidHighlight>
        <ModCovidHighlight>
          Armenia declara su primer caso confirmado
        </ModCovidHighlight>
      </ContentLeft>

      <ContentRight>
        <ModText>
          <strong>En España</strong>, se han registrado 23 más que el día
          anterior. La distribución de casos totales acumulados por comunidad
          autónoma es: 15 Comunidad Valenciana, 14 en Madrid, 12 Andalucía, 9
          Cataluña, 7 en Canarias, 4 Extremadura, 3 Castilla y León, 3 en el
          País Vasco, 2 en Baleares, 1 Castilla La Mancha, 1 en Cantabria, 1 en
          Navarra y 1 Asturias.
        </ModText>
        <ModImage
          src="/images/svg/01-03-spain.svg"
          alt="Casos confirmados en España"
        />
        <ModText>
          El 90% de los casos identificados son importados o relacionados con
          casos importados.
        </ModText>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day09M;
